import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import iconArithmetic from 'stile-shared/assets/icons/icon_arithmetic.svg';
import iconAudioNarration from 'stile-shared/assets/icons/icon_audio_narration.svg';
import iconComprehension from 'stile-shared/assets/icons/icon_comprehension.svg';
import iconCreativeWriting from 'stile-shared/assets/icons/icon_creative_writing.svg';
import iconDataInterpretation from 'stile-shared/assets/icons/icon_data_interpretation.svg';
import iconEstimating from 'stile-shared/assets/icons/icon_estimating.svg';
import iconGlossary from 'stile-shared/assets/icons/icon_glossary.svg';
import iconGraphing from 'stile-shared/assets/icons/icon_graphing.svg';
import iconMeasurement from 'stile-shared/assets/icons/icon_measurement.svg';
import iconNumeracySkills from 'stile-shared/assets/icons/icon_numeracy_skills.svg';
import iconPersuasiveWriting from 'stile-shared/assets/icons/icon_persuasive_writing.svg';
import iconScienceNews from 'stile-shared/assets/icons/icon_science_news.svg';
import iconScientificLiteracy from 'stile-shared/assets/icons/icon_scientific_literacy.svg';
import iconSpatialReasoning from 'stile-shared/assets/icons/icon_spatial_reasoning.svg';
import iconStatistics from 'stile-shared/assets/icons/icon_statistics.svg';
import iconVisualLiteracy from 'stile-shared/assets/icons/icon_visual_literacy.svg';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { IconCardsBlock } from 'stile-shared/src/components/2020/blocks/IconCardsBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function LiteracyAndNumeracyPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/literacy-and-numeracy/cover_literacy_and_numeracy_us.png"
            alt="Two students facing each other working on their laptops"
          />
        }
      />

      <TextBlock
        space={theme.space.m}
        title="Promoting reading and writing in the science classroom"
      >
        <P>
          Stile integrates opportunities to learn and practice reading and writing skills throughout
          all lessons. Creative and persuasive writing activities are included to challenge students
          to make connections while improving their reading and writing skills. For example,
          students are introduced to the nervous system through the context of dogs that can sniff
          out cancer and, after exploring stimulus-response pathways, write a report about the role
          of synapses.
        </P>
        <P>
          Students apply reading comprehension skills in most lessons. They are tested with
          different question types, such as definition matching tasks and fill-in-the-blank
          questions, to ensure they have understood the terminology before moving to higher-order
          questions. Science news stories are lessons that require students to draw on their
          comprehension skills as they learn about relevant, up-to-date scientific phenomena.
        </P>
        <P>
          <Link variant="inherit" url="/what-is-stile/stile-x/">
            Stile X
          </Link>
          , our student notebook and revision guide, further supports reading and writing skills,
          where students take handwritten notes. The glossary section lets them record their own
          definitions of scientific terminology, and in the test preparation section, they practice
          communicating their knowledge in written form by answering test questions. The
          accompanying Stile X app incorporates flashcards and quizzes to support mastery of
          scientific terminology. Students are also provided access to model answers as examples of
          fluent language use.
        </P>
      </TextBlock>

      <IconCardsBlock
        variant="compact"
        cards={[
          { icon: { src: iconComprehension }, title: 'Comprehension activities' },
          { icon: { src: iconScienceNews }, title: 'Science news articles' },
          { icon: { src: iconAudioNarration }, title: 'Audio narration' },
          { icon: { src: iconCreativeWriting }, title: 'Creative writing' },
          { icon: { src: iconPersuasiveWriting }, title: 'Persuasive writing' },
          { icon: { src: iconGlossary }, title: 'Glossary of terms' },
          { icon: { src: iconVisualLiteracy }, title: 'Visual literacy' },
          { icon: { src: iconScientificLiteracy }, title: 'Scientific literacy' },
        ]}
      />

      <TextBlock title="Promoting math in the science classroom">
        <P>
          Great science teaching reinforces the fundamental math required for effective science
          practice. It should also draw direct links between scientific discovery and the practical
          technologies that result. Students should graduate from school with a working knowledge of
          the engineering practice that turns science into technology. Math is the language of
          science; it’s a core tool essential for all scientific endeavors.
        </P>
        <P>
          Stile lessons promote math through both explicit and integrated opportunities. A set of
          dedicated lessons peppered throughout The Stile Curriculum explicitly build students’ math
          skills. For example, students are taught how to calculate measures of central tendency
          within the context of body systems. They are frequently asked to extract meaning from
          graphical representations of real-world data and are challenged to use arithmetic through
          our in-app tools. For example, students learn how to examine trends in data sets while
          analyzing real data sets on trends in vaping. Further to this, all core units
          intentionally integrate the application of math skills within science lessons. For
          example, students are taught how to calculate ratios when learning about the layers of the
          Earth and use this mathematical knowledge to create a scale model.
        </P>
        <P>
          Revision materials are also provided for these skills to support the scientific process,
          including statistics and data interpretation. Formative assessments and end-of-unit
          summative assessments integrate math so teachers can easily see the learning progression
          of all students to inform their teaching.
        </P>
      </TextBlock>

      <IconCardsBlock
        variant="compact"
        cards={[
          { icon: { src: iconGraphing }, title: 'Graphing' },
          { icon: { src: iconNumeracySkills }, title: 'Numeracy skill builders' },
          { icon: { src: iconDataInterpretation }, title: 'Data interpretation' },
          { icon: { src: iconStatistics }, title: 'Statistics' },
          { icon: { src: iconArithmetic }, title: 'Arithmetic' },
          { icon: { src: iconMeasurement }, title: 'Measurement' },
          { icon: { src: iconSpatialReasoning }, title: 'Spatial reasoning' },
          { icon: { src: iconEstimating }, title: 'Estimating' },
        ]}
      />

      <NextPageBlock path="/why-choose-stile/what-teachers-are-saying/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default LiteracyAndNumeracyPage;
